import type { Disclosure } from "./Disclosure.types";

export interface InstitutionFeatures {
  existing_user_account_opening?: boolean;
  instant_account_verification?: string;
  refer_a_member?: boolean;
  refer_a_member_consumer?: boolean;
  refer_a_member_business?: boolean;
  hide_referral_code_from_aoiob?: boolean;
  dao_disable_manual_ach?: boolean;
  bao_allow_owner_info_via_email?: boolean;
  ao_internal_funding?: boolean;
  ao_ach_funding_type?: string;
  glia?: boolean;
  hide_existing_user_option?: boolean;
  personas?: boolean;
  cao_beneficiaries?: boolean;
  eligible_products?: boolean;
  mobile_fis_zelle_2?: boolean;
  ach?: boolean;
  transfers?: boolean;
  p2p?: string;
  bill_pay?: string;
  allow_billpay_from_account?: boolean;
  mobile_loan_payments?: boolean;
  wire_templates?: Array<"fedwire">;
  wires?: boolean;
  zelle?: string | boolean;
  hold_days?: {
    rdc_hold_days?: number;
  };
  staffer_led_use_private_api?: boolean;
  cao_branded_cards?: boolean;
  third_party_enrollment_api?: string;
  bao_ownership_page_with_drawers?: boolean;
  ach_payments?: boolean;
  mobile_ach_payments?: boolean;
  beta_wire_recipients_in_ach_payments?: boolean;
  stop_payment?: boolean;
  stop_payment_by_range?: boolean;
  disable_billpay_native_flow?: boolean;
  cards?: boolean;
  check_order?: boolean;
  card_travel_notes?: boolean;
  olb_enable_card_atm_limit?: boolean;
  olb_enable_card_pos_limit?: boolean;
  card_max_request_limits?: {
    atm?: number;
    pos?: number;
    user_requests_per_month?: number;
  };
  limits?: any;
  olb_allow_add_member?: boolean;
  aoiob_third_party?: boolean;
}

export interface SEO {
  type: string;
  provider: string;
  title: string;
  url: string;
}

export type InstitutionTransferMenuOptionType =
  | "bill_pay"
  | "funds"
  | "naf"
  | "p2p"
  | "wires"
  | "zelle"
  | "loan"
  | "ach_payments";

export interface TransferOption {
  type: Exclude<InstitutionTransferMenuOptionType, "naf">;
  client_id: never;
}

export interface NAFOption {
  type: "naf";
  client_id: string;
}

export type InstitutionTransferMenuOption = TransferOption | NAFOption;

export interface TransferMenu {
  options: InstitutionTransferMenuOption[];
}

export interface BankingMenu {
  transfer: TransferMenu;
}

export interface InstitutionNAFApp {
  scopes: { read?: string; write?: string };
  has_own_layout: boolean;
  redirect_uris: string;
  owners: unknown[];
  client_id: string;
  name: string;
  skip_authorization: boolean;
  created: string;
  updated: string;
  uuid: string;
  publisher: string;
  tagline: string | null;
  description: string;
  image: string;
  is_public: boolean;
  display_in_feed: boolean;
}

export interface AdditionalField {
  name: string;
  value: boolean;
  category: "consumer" | "business";
  options?: Array<string>;
}

export interface DaoFooterDisplaySelector {
  show_membership_text: boolean;
  show_member_dif_text: boolean;
  show_equal_housing_lender_text: boolean;
  show_equal_housing_lender_logo: boolean;
  show_member_dif_logo: boolean;
  show_fdic_ncua_logo: boolean;
  show_powered_by_narmi_text: boolean;
  show_help_phone: boolean;
  show_help_email: boolean;
  show_privacy_policy_and_terms_links: boolean;
}

type DueDiligenceQuestionType =
  | undefined
  | "freeform"
  | "dropdown"
  | "open input"
  | "yes/no";
export interface DueDiligenceQuestion {
  question: string;
  portalVersion?: string;
  questionType: DueDiligenceQuestionType;
  options?: Array<string>;
  label?: string; // for freeform or dropdown input
}

export enum DepositInsurer {
  NCUA = "NCUA",
  FDIC = "FDIC",
  NONE = "",
}

declare global {
  namespace API {
    interface Institution {
      captcha_public_key?: string;
      show_captcha_bao?: boolean;
      show_captcha_dao?: boolean;
      is_production?: boolean;
      authorize_dot_net_login_id?: string;
      authorize_dot_net_client_key?: string;
      authorize_dot_net_script_url?: string;
      authorize_dot_net_client_key_business?: string;
      authorize_dot_net_login_id_business?: string;
      terms_url?: string;
      features?: InstitutionFeatures;
      dao_ask_additional_fields?: Array<AdditionalField>;
      dao_fund_max_amount_debit?: number;
      dao_fund_max_amount_ach?: number;
      dao_fund_card_network?: Array<string>;
      dao_ask_field_occupation_question_setting?: boolean;
      dao_footer_display_selector?: DaoFooterDisplaySelector;
      dao_disable_joint?: boolean;
      dao_alloy_validation_prechecks?: boolean;
      dao_alloy_doc_verification_sdk?: any;
      dao_require_joint_for_minors?: boolean;
      has_mailing_address?: boolean;
      allow_po_box_primary_address?: boolean;
      internal_name?: string;
      long_name: string;
      logo_url?: string;
      bao_disclosures?: Array<Disclosure>;
      dao_courtesy_pay_disclosure?: string;
      cao_consent_esign_disclosure?: string;
      dao_disclosures?: Array<Disclosure>;
      dao_min_ownership_percent_llc?: number;
      dao_min_ownership_percent_corporation?: number;
      help_email?: string;
      routing_number?: string;
      type?: string;
      bao_success_screen_new_customer_enroll_url?: string;
      bao_success_screen_existing_customer_enroll_url?: string;
      dao_success_screen_new_customer_enroll_url?: string;
      dao_success_screen_existing_customer_enroll_url?: string;
      dao_success_screen_cta_new_member?: string;
      dao_success_screen_cta_returning_member?: string;
      third_party_auto_enrollment?: string;
      third_party_enrollment_api?: string;
      primary_color?: string;
      primary_color_hex?: string;
      secondary_color?: string;
      short_name?: string;
      tertiary_color?: string;
      heap_app_id?: string;
      google_tag_manager_id?: string;
      google_tag_manager_whitelist?: Array<string>;
      google_analytics_id?: string;
      allow_po_box_mailing_address?: boolean;
      favicon_url?: string;
      bao_allow_only_one_control_person?: boolean;
      member_dif?: boolean;
      dif_logo_url?: string;
      equal_housing_logo_url?: string;
      help_phone?: string;
      privacy_url?: string;
      cdd_bao_questions?: Array<DueDiligenceQuestion>;
      cdd_cao_questions?: Array<DueDiligenceQuestion>;
      prompt_account_number?: boolean;
      bao_neuro_id_site_id?: string;
      cao_neuro_id_site_id?: string;
      cao_existing_core_user_applicant_handling?:
        | "USE_EXISTING_MEMBER_ID"
        | "CREATE_NEW_MEMBER_ID"
        | "USER_ASSOCIATE"
        | "USER_ASSOCIATE_OR_NEW";
      application_submitting_timeout?: number;
      s3_images_bucket?: string;
      ao_fund_max_amount_narmi_ach?: number;
      is_demo?: boolean;
      is_local?: boolean;
      announcement?: string;
      main_domain?: string;
      digital_account_opening_url?: string;
      existing_digital_account_opening_url?: string;
      existing_digital_account_opening_message?: string;
      mobile_login_link_to_dao_url?: boolean;
      card_phone?: string;
      facebook?: string;
      twitter?: string;
      instagram?: string;
      linkedin?: string;
      card_network?: string;
      contact_url?: string;
      lost_card_url?: string;
      location_hours_url?: string;
      chat_script?: string;
      ach_in_url?: string;
      ach_out_url?: string;
      service_urls?: string[];
      nav_order?: string[];
      // mobile_navigation - needs React 18 for react native webview props
      address_1?: string;
      address_2?: string;
      address_3?: string;
      ect?: string;
      fdic_ncua_logo_url?: string;
      deposit_insurer?: DepositInsurer;
      mobile_splash_screen_logo_url?: string;
      framework_css_url?: string;
      framework_js_url?: string;
      header_font?: string;
      ach_terms_url?: string;
      rdc_terms_url?: string;
      rdc_info?: string;
      show_enrollment_captcha?: boolean;
      ios_app_id?: string;
      android_app_id?: string;
      esign_url?: string;
      disable_daily_transfer?: boolean;
      disable_semimonthly_transfer?: boolean;
      transfer_disclosure?: string;
      ach_payment_disclosure?: string;
      wire_disclosure?: string;
      wire_display_fee?: string;
      wire_info?: string;
      stoppayment_info?: string;
      new_customer_url?: string;
      sso_urls?: SEO[];
      banking_url?: string;
      mfa_required?: boolean;
      referral_terms_url?: string;
      referral_offer_text?: string;
      referral_additional_offer_text?: string;
      referral_share_message?: string;
      referral_new_customer_url?: string;
      referral_new_business_customer_url?: string;
      referral_email_subject?: string;
      referral_amount?: number;
      routing_url?: string;
      rdc_instructions?: string;
      keywords?: string[];
      description?: string;
      enrollment_code?: string;
      dao_minimum_applicant_birth_year?: number;
      dao_custom_javascript_setting?: string;
      dao_complete_refresh_seconds?: number;
      dao_minimum_applicant_age?: number;
      dao_skip_product_selection?: boolean;
      cao_staffer_led_hold_kyc?: boolean;
      dao_staffer_led_enable_referral_code?: {
        bao: boolean;
        cao: boolean;
      };
      dao_initial_hold_days_card?: number;
      dao_initial_hold_days_ach?: number;
      dao_referral_offer_amount?: number;
      dao_referral_terms_url?: string;
      ao_force_full_application_flow?: boolean;
      ao_alloy_journey_token?: string;
      business_banking_enabled?: boolean;
      external_accounts_allows_business_purpose?: boolean;
      sudo_mode_required_for_wires?: boolean;
      sudo_mode_required_for_ach_payments?: boolean;
      sudo_mode_required_for_add_member?: boolean;
      online_banking_custom_javascript?: string;
      ach_allows_pull?: boolean;
      ach_allows_push?: boolean;
      ach_use_available_balance_for_scheduled_push?: boolean;
      custom_footer?: string;
      auth_username?: boolean;
      permitted_mfa_methods?: string[];
      banker_led_ao_locations?: string[];
      staffer_led_metadata_fields?: {
        key_name: string;
        key_display_name: string;
        dropdown_values: {
          value_name: string;
          value_display_name: string;
        }[];
      }[];
      staffer_led_ao_seg_codes?: {
        key_name: string;
        possible_values: string[];
      };
      institution_public_google_places_url?: string;
      loan_pay_by_card_fee?: number | string;
      core_update_estatement?: boolean;
      show_checkbox_on_enrollment_preferences_page?: boolean;
      require_viewing_sample_pdf_for_paperless_enrollment?: boolean;
      include_esign_disclosure_link_on_review_disclosures_page?: boolean;
      core_update_address?: boolean;
      forgot_password_url?: string;
      help_card_phone?: string;
      register_url?: string;
      default_rdc_hold_days?: number;
      ao_allow_skip_funding?: boolean;
      banking_menus?: BankingMenu;
      apps?: InstitutionNAFApp[];
      display_bao_footer?: boolean;
      cao_beneficiaries?: boolean;
      beneficiaries_relationship_codes?: string[][];
      cdd_business_slao_questions?: DueDiligenceQuestion[];
      cdd_consumer_slao_questions?: DueDiligenceQuestion[];
      wire_reason_types?: string[];
      joint_addresses_are_same_checkbox?: boolean;
      third_party_banking_login_url?: string;
      license_keys?: LicenseKeys;
      submitted_screen_show_account_info?: boolean;
      travel_notes_excluded_countries?: string[];
      separate_primary_and_joint_contact_info?: boolean;
      aoiob_allow_joint_applicants?: boolean;
      ach_business_description?: string;
    }

    type GetInstitutionResponse = {
      institution: Institution;
    };

    /**
     * Services that required license keys
     * Returned in institution settings under the "license_keys" record
     */
    type LicensedService = "misnap";
    type LicenseKeys = Record<LicensedService, string> | Record<string, never>;
  }
}

export type Institution = API.Institution;

export type InstitutionFeatureFlags = keyof API.Institution;
