import * as Yup from "yup";
import { isEqual } from "lodash";

import { formatNumber } from "../../entities/utils/currency";
import { dollarsToCents, centsToDollars } from "../../../utils/money";
import { CARD_LIMIT_TYPE } from "../../entities/cardLimitIncrease";

import type { InstitutionFeatures } from "../../../../types";
import type { ReactLocalization } from "@fluent/react";

const errors = {
  integer: "Amount must be a whole number.",
  positive: "Amount must be positive.",
  min: "Limit cannot be decreased.",
  max(limit: string) {
    return `Maximum amount of ${limit}.`;
  },
  atLeastOne: "Please enter an updated amount.",
} as const;

export const createCardLimitValidationSchema = (
  l10n: ReactLocalization,
  card_max_request_limits: InstitutionFeatures["card_max_request_limits"],
  limits?: API.CardLimits,
) => {
  const minimimAtmLimitString = formatNumber(
    `${centsToDollars(limits?.atm_limit ?? (0 as Cents))}`,
  );
  const minimumPosLimitString = formatNumber(
    `${centsToDollars(limits?.pos_limit ?? (0 as Cents))}`,
  );

  const maximumAtmLimitString = formatNumber(
    `${card_max_request_limits?.atm ?? 0}`,
  );
  const maximumPosLimitString = formatNumber(
    `${card_max_request_limits?.pos ?? 0}`,
  );

  const defaultLimitSchema = () =>
    Yup.number()
      .nullable()
      .integer(l10n.getString("card-limits-error-integer", {}, errors.integer))
      .positive(
        l10n.getString("card-limits-error-positive", {}, errors.positive),
      );

  const validationSchema = Yup.object({
    atmLimit: defaultLimitSchema()
      .min(
        limits?.atm_limit ?? 0,
        l10n.getString("card-limits-error-min", {}, errors.min),
      )
      .max(
        (card_max_request_limits?.atm &&
          dollarsToCents(card_max_request_limits?.atm as Dollars)) ??
          Infinity,
        l10n.getString(
          "card-limits-error-max",
          {
            limit: maximumAtmLimitString,
          },
          errors.max(maximumAtmLimitString),
        ),
      )
      .when("limitsEnabled", {
        is: (limitsEnabled: CARD_LIMIT_TYPE[]) =>
          limitsEnabled.includes(CARD_LIMIT_TYPE.ATM),
        then: (schema) =>
          schema.required(l10n.getString("card-limits-error-required")),
      }),
    posLimit: defaultLimitSchema()
      .min(
        limits?.pos_limit ?? 0,
        l10n.getString("card-limits-error-min", {}, errors.min),
      )
      .max(
        (card_max_request_limits?.pos &&
          dollarsToCents(card_max_request_limits?.pos as Dollars)) ??
          Infinity,
        l10n.getString(
          "card-limits-error-max",
          {
            limit: maximumPosLimitString,
          },
          errors.max(maximumPosLimitString),
        ),
      )
      .when("limitsEnabled", {
        is: (limitsEnabled: CARD_LIMIT_TYPE[]) =>
          limitsEnabled.includes(CARD_LIMIT_TYPE.POS),
        then: (schema) =>
          schema.required(l10n.getString("card-limits-error-required")),
      }),
    limitsEnabled: Yup.array()
      .of(Yup.mixed<CARD_LIMIT_TYPE>().oneOf(Object.values(CARD_LIMIT_TYPE)))
      .required(),
  }).test(
    "at-least-one",
    "",
    function atLeastOne(
      this: Yup.TestContext,
      { limitsEnabled, atmLimit, posLimit },
    ) {
      const isAtmAndPosLimitsMatch =
        isEqual(
          new Set(limitsEnabled),
          new Set([CARD_LIMIT_TYPE.ATM, CARD_LIMIT_TYPE.POS]),
        ) &&
        atmLimit === limits?.atm_limit &&
        posLimit === limits?.pos_limit;
      const isAtmLimitOnlyMatch =
        isEqual(limitsEnabled, [CARD_LIMIT_TYPE.ATM]) &&
        atmLimit === limits?.atm_limit;
      const isPosLimitOnlyMatch =
        isEqual(limitsEnabled, [CARD_LIMIT_TYPE.POS]) &&
        posLimit === limits?.pos_limit;
      if (
        isAtmAndPosLimitsMatch ||
        isAtmLimitOnlyMatch ||
        isPosLimitOnlyMatch
      ) {
        return this.createError({
          path: "limitsEnabled",
          message: l10n.getString(
            "card-limits-error-at-least-one",
            {},
            errors.atLeastOne,
          ),
        });
      }
      return true;
    },
  );

  return validationSchema;
};
