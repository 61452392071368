// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".subheaderText--Pecex{margin-top:var(--space-xxs);margin-bottom:var(--space-l)}", "",{"version":3,"sources":["webpack://./components/SudoContext/RequestOTP.module.scss"],"names":[],"mappings":"AAAA,sBACE,2BAAA,CACA,4BAAA","sourcesContent":[".subheaderText {\n  margin-top: var(--space-xxs);\n  margin-bottom: var(--space-l);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subheaderText": "subheaderText--Pecex"
};
export default ___CSS_LOADER_EXPORT___;
