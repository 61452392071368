// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".actionBar--NSqNo{display:flex;justify-content:space-between;align-items:center}.actionBar--NSqNo.alignRight--cmUPj{justify-content:flex-end}.continueButtonWrapper--rpeKU{margin-left:var(--space-m)}", "",{"version":3,"sources":["webpack://./components/SudoContext/ActionBar.module.scss"],"names":[],"mappings":"AAAA,kBACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,oCACE,wBAAA,CAIJ,8BACE,0BAAA","sourcesContent":[".actionBar {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  &.alignRight {\n    justify-content: flex-end;\n  }\n}\n\n.continueButtonWrapper {\n  margin-left: var(--space-m);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionBar": "actionBar--NSqNo",
	"alignRight": "alignRight--cmUPj",
	"continueButtonWrapper": "continueButtonWrapper--rpeKU"
};
export default ___CSS_LOADER_EXPORT___;
