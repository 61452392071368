import { useMemo } from "react";
import { Dialog } from "cerulean";
import { useLocation, useNavigate, matchRoutes } from "react-router-dom";
import { useSudoContext } from "../SudoContext";
import RequestOTP from "../SudoContext/RequestOTP";
import SubmitOTP from "../SudoContext/SubmitOTP";

const SudoDialog = () => {
  const { originalRoute, requestOtpRoute, submitOtpRoute } = useSudoContext();
  const location = useLocation();
  const navigate = useNavigate();

  const handleClose = () => {
    if (originalRoute) {
      navigate(originalRoute); // Navigate back to the previous route
    }
  };

  // Determine if the current route is a Sudo route
  const isRequestOtpRoute = useMemo(
    () => matchRoutes([{ path: requestOtpRoute }], location.pathname),
    [location.pathname],
  );
  const isSubmitOtpRoute = useMemo(
    () => matchRoutes([{ path: submitOtpRoute }], location.pathname),
    [location.pathname],
  );
  const isSudoRoute = isRequestOtpRoute || isSubmitOtpRoute;

  return (
    <Dialog
      isOpen={isSudoRoute}
      onUserDismiss={handleClose}
      width="385px"
      title={
        isRequestOtpRoute
          ? "Let's verify your account"
          : "Enter verification code"
      }
    >
      {isRequestOtpRoute && <RequestOTP isInDialog />}
      {isSubmitOtpRoute && <SubmitOTP isInDialog />}
    </Dialog>
  );
};

export default SudoDialog;
