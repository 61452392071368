import { apiExceptionErrorCode } from "../../../types/models/v1/Error.types";

import type {
  ErrorOrWarning,
  ApiError,
  FormError,
  APIClientException,
  APIClientExceptionError,
  APIClientExceptionWarning,
} from "../../../types/models/v1/Error.types";
import type {
  OverrideRunProps,
  LayeredOverrideRunProps,
  Data,
  ErrorResponse,
} from "./request.types";

export type AnyException<D = Data> =
  | FormError<D>
  | APIClientException
  | ApiError;

export function combineRunProps<D = Data, E = Data>(
  innerOrFn: LayeredOverrideRunProps<D, E>,
  outer: LayeredOverrideRunProps<D, E>,
) {
  const inner = typeof innerOrFn === "function" ? innerOrFn() : innerOrFn;

  if (typeof outer === "function") {
    return outer(inner);
  }

  const onSuccess =
    inner.onSuccess || outer.onSuccess
      ? (data: D) => {
          if (inner.onSuccess) inner.onSuccess(data);
          if (outer.onSuccess) outer.onSuccess(data);
        }
      : undefined;

  const onData =
    inner.onData || outer.onData
      ? (data: D) => {
          if (inner.onData) inner.onData(data);
          if (outer.onData) outer.onData(data);
        }
      : undefined;

  const onError =
    inner.onError || outer.onError
      ? (e: ErrorResponse<E>) => {
          if (inner.onError) inner.onError(e, () => null);
          if (outer.onError) outer.onError(e, () => null);
        }
      : undefined;

  const onFinally =
    inner.onFinally || outer.onFinally
      ? () => {
          if (inner.onFinally) inner.onFinally();
          if (outer.onFinally) outer.onFinally();
        }
      : undefined;

  return {
    ...inner,
    ...outer,
    onSuccess,
    onData,
    onError,
    onFinally,
  } as OverrideRunProps<D, E>;
}

export const parseFormError = (json?: FormError<Data>) => {
  const message =
    typeof json?.message === "string" ? [json?.message] : json?.message;
  const errors = Object.values(message || {}).flat();
  return errors;
};

export const parseFormErrorResponse = async <
  E extends FormError<Data> = FormError<Data>,
>(
  e: ErrorResponse<E>,
) => {
  try {
    const json = await e?.response?.json();
    return parseFormError(json);
  } catch (err) {
    return [];
  }
};

type ParsedErrorResponse = {
  errors: APIClientExceptionError[];
  warnings: APIClientExceptionWarning[];
};

const GENERIC_ERROR_MESSAGE =
  "We're having trouble completing your request. Please try again later.";

const JSON_PARSING_ERROR = {
  errors: [
    {
      code: apiExceptionErrorCode("jsonParseError"),
      description: GENERIC_ERROR_MESSAGE,
    },
  ],
  warnings: [],
} as ParsedErrorResponse;

function isApiError(json: AnyException): json is ApiError {
  return "id" in json && !!json.id && typeof json.message === "string";
}

const parseApiError = ({ id, message }: ApiError) =>
  ({
    errors: [
      {
        code: id,
        description: message,
      },
    ],
    warnings: [],
  }) satisfies ParsedErrorResponse;

function isAPIClientException(json: AnyException): json is APIClientException {
  return (
    ("errors" in json && !!json.errors) ||
    ("warnings" in json && !!json.warnings)
  );
}

const parseApiClientException = ({
  message,
  reason,
  warnings = [],
  errors = [],
}: APIClientException) => {
  const description = message?.message || reason;

  if (warnings.length === 0 && errors.length === 0) {
    return {
      warnings: [],
      errors: [
        {
          code: apiExceptionErrorCode("genericApiClientException"),
          description,
        },
      ],
    } satisfies ParsedErrorResponse;
  }

  return {
    warnings,
    errors,
  } satisfies ParsedErrorResponse;
};

export const parseErrorResponse = async <E extends AnyException>(
  e: ErrorResponse<E>,
) => {
  try {
    const json = await e?.response?.json();
    if (!json) {
      return JSON_PARSING_ERROR;
    }

    if (isAPIClientException(json)) {
      return parseApiClientException(json);
    }

    if (isApiError(json)) {
      return parseApiError(json);
    }

    const formErrors = parseFormError(json);
    return {
      warnings: [],
      errors: formErrors.map<ErrorOrWarning<string>>((formError) => ({
        code: apiExceptionErrorCode("formError"),
        description: formError,
      })),
    };
  } catch (err) {
    return JSON_PARSING_ERROR;
  }
};

export const GENERIC_API_ERROR = {
  id: "generic_api_error",
  message: GENERIC_ERROR_MESSAGE,
} as ApiError;

export const getApiError = async <E extends ApiError<Data> = ApiError<Data>>(
  e: ErrorResponse<E>,
) => {
  try {
    return (await e?.response?.json()) || GENERIC_API_ERROR;
  } catch (err) {
    return GENERIC_API_ERROR;
  }
};

export const notNull = <T>(x: T | null): x is T => !!x;
