import { ReactNode } from "react";

import { catcat as cc } from "cerulean";
import styles from "./ActionBar.module.scss";

interface ActionBarProps {
  children: ReactNode;
  align?: "center" | "right";
}

const ActionBar = ({ children, align = "center" }: ActionBarProps) => (
  <div
    className={cc(styles.actionBar, "margin--top--xl", {
      [styles.alignRight]: align === "right",
    })}
  >
    {children}
  </div>
);

export default ActionBar;
