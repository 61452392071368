import { Link } from "react-router-dom";
import { Button } from "cerulean";

const BackButton = ({ to, label = "Back" }: { to: string; label?: string }) => (
  <Link to={to}>
    <Button type="button" kind="plain">
      {label}
    </Button>
  </Link>
);

export default BackButton;
