import { useEffect } from "react";
import { useLocalization } from "@fluent/react";
import { useNavigate, Navigate } from "react-router-dom";
import { entities, modules, centsToDollars, dollarsToCents } from "byzantine";
import {
  CARD_LIMIT_TYPE,
  UpdateLimitsPayload,
} from "byzantine/src/dbbl/businessLogic/entities/cardLimitIncrease";

import {
  catcat as cc,
  formatNumber,
  ContentCard,
  LoadingSkeleton,
  Alert,
  useNotificationContext,
} from "cerulean";
import type { InstitutionFeatures } from "byzantine/src/types";
import { useUserFeatures } from "../../../../contexts/UserFeaturesContext";
import DrawerLayout from "../../../../DrawerLayout";

import { useSudoContext, SudoProvider } from "../../../../SudoContext";
import AmountTextInput from "../../../../form/AmountTextInput";

import * as formAdapters from "../../../../../src/adapters";

import BackButton from "../../../../../src/modules/transfers/wire/templates/TemplatesList/BackButton";
import CardImage from "../../CardImage";

import styles from "./LimitIncrease.module.scss";
import SudoDialog from "../../../../SudoDialog";

interface DisabledLimitIncreaseProps {
  card: API.Card;
}

const formatSmallTextAmount = (amount: number | undefined) =>
  formatNumber(amount, "currency", "auto", false);

const getLimitIncreaseInfoKey = (limits: API.CardLimits | undefined) => {
  if (
    limits?.has_pos_limit_increased_today &&
    limits?.has_atm_limit_increased_today
  ) {
    return "card-limits-existing-increase-info-atm-and-pos";
  } else if (limits?.has_pos_limit_increased_today) {
    return "card-limits-existing-increase-info-pos";
  } else if (limits?.has_atm_limit_increased_today) {
    return "card-limits-existing-increase-info-atm";
  }

  return "";
};

const getAdditionalInfoKey = (features: InstitutionFeatures) => {
  if (
    features.olb_enable_card_pos_limit &&
    features.olb_enable_card_atm_limit
  ) {
    return "card-limits-additional-increase-info-atm-and-pos";
  } else if (features.olb_enable_card_pos_limit) {
    return "card-limits-additional-increase-info-pos";
  } else if (features.olb_enable_card_atm_limit) {
    return "card-limits-additional-increase-info-atm";
  }

  return "";
};

const DisabledLimitIncrease = ({ card }: DisabledLimitIncreaseProps) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as InstitutionFeatures;
  const limits = entities.cardLimitIncrease.useCardLimits(card.id);
  const navigate = useNavigate();
  const goBack = () => navigate(`/cards/${card.id}`);

  // Wait until limits and features are loaded
  if (!limits || !features) {
    return <LoadingSkeleton isLoading={true} />;
  }

  return (
    <DrawerLayout
      onSave={() => {}} // noop
      onCancel={() => {}}
      isBottomBarHidden
    >
      <div className={styles.container}>
        <BackButton
          text={l10n.getString("card-limits-back-button")}
          className="margin--bottom--l"
          onClick={goBack}
        />
        <h2 className={styles.header}>{l10n.getString("card-limits-title")}</h2>
        <div className="margin--top--xs margin--bottom--xl">
          {l10n.getString("card-limits-description")}
        </div>
        <div className="margin--bottom--l">
          <Alert isActive kind="error" isDismissable={false}>
            {l10n.getString("card-limits-max-requests-error")}
          </Alert>
        </div>
        <ContentCard kind="bordered" radiusSize="m">
          <div className="alignChild--left--top">
            <div className={styles.imageContainer}>
              <CardImage
                textColor={card.text_color}
                isLocked={card.state === "locked"}
                image={card.image}
                network={card.network}
                size="x-small"
              />
            </div>
            <div className="margin--left--s">
              <div className="fontColor--heading fontWeight--bold margin--bottom--xss">
                {card.name}
              </div>
              <div className="fontColor--secondary fontSize--s">
                *{card.last_four_digits}
              </div>
            </div>
          </div>
          <div className={cc("margin--y--l", styles.dividerLine)} />
          {features.olb_enable_card_pos_limit && !!limits.pos_limit && (
            <>
              <div className="fontColor--heading fontWeight--bold margin--bottom--s">
                {l10n.getString("card-limits-pos-section")}
              </div>
              <div className="margin--bottom--l">
                <AmountTextInput
                  label={l10n.getString("card-limits-pos-input")}
                  value={`${centsToDollars(limits.pos_limit)}`}
                  disabled
                />
              </div>
            </>
          )}
          {features.olb_enable_card_atm_limit && !!limits.atm_limit && (
            <>
              <div className="fontColor--heading fontWeight--bold margin--bottom--s">
                {l10n.getString("card-limits-atm-section")}
              </div>
              <div className="margin--bottom--l">
                <AmountTextInput
                  label={l10n.getString("card-limits-atm-input")}
                  value={`${centsToDollars(limits.atm_limit)}`}
                  disabled
                />
              </div>
            </>
          )}
          <div className="fontColor--secondary fontSize--xs">
            {l10n.getString(getAdditionalInfoKey(features), {
              posMaxLimit: formatSmallTextAmount(
                features?.card_max_request_limits?.pos,
              ),
              atmMaxLimit: formatSmallTextAmount(
                features?.card_max_request_limits?.atm,
              ),
            })}
          </div>
        </ContentCard>
      </div>
    </DrawerLayout>
  );
};

interface LimitIncreaseProps {
  onUserDismiss: () => void;
  card: API.Card;
}

const LimitIncrease = ({ card, onUserDismiss }: LimitIncreaseProps) => {
  const navigate = useNavigate();
  const { l10n } = useLocalization();
  const { sendNotification } = useNotificationContext();
  const features = useUserFeatures() as InstitutionFeatures;

  const { establishSudo } = useSudoContext();

  const goBack = () => navigate(`/cards/${card.id}`);

  const limits = entities.cardLimitIncrease.useCardLimits(card.id);
  const { form, onValidate } =
    modules.cardLimitIncrease.useCardLimitIncreaseForm(
      card.id,
      l10n,
      features.card_max_request_limits,
    );

  const { send: updateLimits, loading: isUpdating } =
    entities.cardLimitIncrease.useUpdateCardLimits({
      card,
      onSuccess: () => {
        navigate("/cards");
      },
    });

  // Wait until limits and features are loaded
  if (!limits || !features) {
    return <LoadingSkeleton isLoading={true} />;
  }

  const onSubmit = async () => {
    const isValid = await onValidate();

    if (!isValid) {
      return;
    }

    establishSudo("/cards", async () => {
      const payload: UpdateLimitsPayload = {};

      const atmLimit = form.values.atmLimit || undefined;
      const posLimit = form.values.posLimit || undefined;

      if (features.olb_enable_card_atm_limit && atmLimit !== limits.atm_limit) {
        payload.atmLimit = atmLimit;
      }

      if (features.olb_enable_card_pos_limit && posLimit !== limits.pos_limit) {
        payload.posLimit = posLimit;
      }

      if (Object.keys(payload).length === 0) {
        // Should never get here
        sendNotification({
          type: "negative",
          text: "No limits submitted.",
        });
        return;
      }

      await updateLimits(payload);
    });
  };

  const {
    value: posLimitValue,
    onChange: posOnChange,
    ...posLimitFields
  } = formAdapters.fieldWithOnChange(form, "posLimit");

  const {
    value: atmLimitValue,
    onChange: atmOnChange,
    ...atmLimitFields
  } = formAdapters.fieldWithOnChange(form, "atmLimit");

  const handleLimitChange = (
    onChange: (value: number | string) => void,
    value: string,
  ) => {
    const numericValue = parseInt(value, 10);
    if (!Number.isNaN(numericValue)) {
      onChange(dollarsToCents(numericValue as Dollars));
    } else {
      onChange("");
    }
  };

  const formatToDollarValue = (value: Cents) =>
    ["$", ""].includes(`${value}`) ? "$" : `${centsToDollars(value)}`;

  const limitIncreaseInfoKey = getLimitIncreaseInfoKey(limits);

  return (
    <DrawerLayout
      onSave={onSubmit}
      saveLabel={l10n.getString("card-limits-submit-button")}
      onCancel={onUserDismiss}
      cancelLabel={l10n.getString("card-limits-cancel-button")}
      isSaveDisabled={isUpdating}
    >
      <div className={styles.container}>
        <BackButton
          text={l10n.getString("card-limits-back-button")}
          className="margin--bottom--l"
          onClick={goBack}
        />
        <h2 className={styles.header}>{l10n.getString("card-limits-title")}</h2>
        <div className="margin--top--xs margin--bottom--xl">
          {l10n.getString("card-limits-description")}
        </div>
        {limitIncreaseInfoKey && (
          <div className="margin--bottom--l">
            <Alert isActive kind="info" isDismissable={false}>
              {l10n.getString(limitIncreaseInfoKey)}
            </Alert>
          </div>
        )}
        <ContentCard kind="bordered" radiusSize="m">
          <div className="alignChild--left--top">
            <div className={styles.imageContainer}>
              <CardImage
                textColor={card.text_color}
                isLocked={card.state === "locked"}
                image={card.image}
                network={card.network}
                size="x-small"
              />
            </div>
            <div className="margin--left--s">
              <div className="fontColor--heading fontWeight--bold margin--bottom--xss">
                {card.name}
              </div>
              <div className="fontColor--secondary fontSize--s">
                *{card.last_four_digits}
              </div>
            </div>
          </div>
          <div className={cc("margin--y--l", styles.dividerLine)} />
          {form.errors.limitsEnabled && (
            <div className="margin--bottom--l">
              <Alert isActive kind="error" isDismissable={false}>
                {form.errors.limitsEnabled}
              </Alert>
            </div>
          )}
          {features.olb_enable_card_pos_limit && (
            <>
              <div className="fontColor--heading fontWeight--bold margin--bottom--s">
                {l10n.getString("card-limits-pos-section")}
              </div>
              <div className="margin--bottom--l">
                <AmountTextInput
                  label={l10n.getString("card-limits-pos-input")}
                  value={formatToDollarValue(posLimitValue)}
                  onChange={(value: string) =>
                    handleLimitChange(posOnChange, value)
                  }
                  {...posLimitFields}
                />
              </div>
            </>
          )}
          {features.olb_enable_card_atm_limit && (
            <>
              <div className="fontColor--heading fontWeight--bold margin--bottom--s">
                {l10n.getString("card-limits-atm-section")}
              </div>
              <div className="margin--bottom--l">
                <AmountTextInput
                  label={l10n.getString("card-limits-atm-input")}
                  value={formatToDollarValue(atmLimitValue)}
                  onChange={(value: string) =>
                    handleLimitChange(atmOnChange, value)
                  }
                  {...atmLimitFields}
                />
              </div>
            </>
          )}
          <div className="fontColor--secondary fontSize--xs">
            {l10n.getString(getAdditionalInfoKey(features), {
              posMaxLimit: formatSmallTextAmount(
                features?.card_max_request_limits?.pos,
              ),
              atmMaxLimit: formatSmallTextAmount(
                features?.card_max_request_limits?.atm,
              ),
            })}
          </div>
        </ContentCard>
      </div>
    </DrawerLayout>
  );
};

const LimitIncreaseWrapper = ({ card, onUserDismiss }: LimitIncreaseProps) => {
  const { l10n } = useLocalization();
  const features = useUserFeatures() as InstitutionFeatures;
  const limitsEnabled = [
    { feature: features.olb_enable_card_atm_limit, type: CARD_LIMIT_TYPE.ATM },
    { feature: features.olb_enable_card_pos_limit, type: CARD_LIMIT_TYPE.POS },
  ]
    .filter((item) => item.feature)
    .map((item) => item.type);

  const hasLimitIncreaseFeature =
    modules.cardLimitIncrease.hasLimitIncreaseFeature(limitsEnabled, card);

  const initialValues =
    modules.cardLimitIncrease.useCardLimitIncreaseInitialValues(
      card.id,
      limitsEnabled,
    );
  const validationSchema =
    modules.cardLimitIncrease.useCardLimitIncreaseValidationSchema(
      card.id,
      l10n,
      features.card_max_request_limits,
    );

  const { can_user_request_limit_increase: canRequestLimitIncrease } =
    entities.cardLimitIncrease.useCardLimits(card.id) || {};

  const { send: fetchLimits, loading: isLoading } =
    entities.cardLimitIncrease.useGetCardLimits({
      cardId: card.id,
      onSuccess: () => {},
    });

  useEffect(() => {
    if (!hasLimitIncreaseFeature) {
      return;
    }
    fetchLimits();
  }, []);

  if (!hasLimitIncreaseFeature) {
    return <Navigate to={`/cards/${card.id}`} replace />;
  }

  if (!initialValues || !validationSchema || isLoading) {
    return <LoadingSkeleton isLoading={true} />;
  }

  return (
    <SudoProvider basename={`cards/${card.id}/limit_increase`}>
      <modules.cardLimitIncrease.cardLimitIncreaseForm.Provider
        initialValues={initialValues}
      >
        {canRequestLimitIncrease ? (
          <LimitIncrease card={card} onUserDismiss={onUserDismiss} />
        ) : (
          <DisabledLimitIncrease card={card} />
        )}
        <SudoDialog />
      </modules.cardLimitIncrease.cardLimitIncreaseForm.Provider>
    </SudoProvider>
  );
};

export default LimitIncreaseWrapper;
