// @ts-ignore
import React from "react";
import { AlertCircle } from "react-feather";
import ReactMarkdown from "react-markdown";
import { IconButton } from "@narmi/design_system";

const NegativeMessage = ({
  text,
  dismissMessage,
}: {
  text: string;
  dismissMessage: () => void;
}) => (
  <div className="message-content negative">
    <AlertCircle color="var(--color-errorDark)" />
    <span className="margin--right--l" data-testid="notification-negative">
      <ReactMarkdown>{text}</ReactMarkdown>
    </span>
    <IconButton label="Dismiss" kind="plain" name="x" size="xl" onClick={dismissMessage} />
  </div>
);

export default NegativeMessage;
