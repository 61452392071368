import {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { fetchIsSudo } from "./utils";

export enum SudoRoutes {
  RequestOTP = "/sudo/request-otp",
  SubmitOTP = "/sudo/submit-otp",
}

type OnEstablishedSudoType = null | (() => Promise<void>);

interface SudoContextType {
  originalRoute: string | null;
  destinationRoute: string | null;
  setDestinationRoute: (route: string) => void;
  setOriginalRoute: (route: string) => void;
  formData: any;
  setFormData: (data: any) => void;
  onFormDataChange: (update: any) => void;
  establishSudo: (
    destinationRoute: string,
    onEstablishedSudoCb?: OnEstablishedSudoType,
  ) => Promise<void>;
  onEstablishedSudo: OnEstablishedSudoType;
  cancelSudo: () => void;
  useSetInitialSteps: (steps: number) => void;
  totalSteps: number | null;
  requestOtpRoute: string;
  submitOtpRoute: string;
}

const defaultSudoContext: SudoContextType = {
  originalRoute: null,
  destinationRoute: null,
  setDestinationRoute: () => {},
  setOriginalRoute: () => {},
  formData: null,
  setFormData: () => {},
  onFormDataChange: () => {},
  establishSudo: async () => {},
  onEstablishedSudo: () => Promise.resolve(),
  cancelSudo: () => {},
  useSetInitialSteps: () => {},
  totalSteps: null,
  requestOtpRoute: "",
  submitOtpRoute: "",
};

export const NUMBER_OF_SUDO_STEPS = 1;
const SudoContext = createContext<SudoContextType>(defaultSudoContext);

export const useSudoContext = () => useContext(SudoContext);

interface SudoProviderProps {
  children: ReactNode;
  basename?: string;
}

export const SudoProvider = ({
  children,
  basename = "/",
}: SudoProviderProps) => {
  const [onEstablishedSudo, setOnEstablishedSudo] =
    useState<OnEstablishedSudoType>(null);
  const [originalRoute, setOriginalRoute] = useState<
    SudoContextType["originalRoute"]
  >(defaultSudoContext.originalRoute);
  const [destinationRoute, setDestinationRoute] = useState<
    SudoContextType["destinationRoute"]
  >(defaultSudoContext.destinationRoute);
  const [formData, setFormData] = useState<SudoContextType["formData"]>(
    defaultSudoContext.formData,
  );
  const [initialSteps, setInitialSteps] = useState<number | null>(null);
  const [hasSudoBeenEstablished, setHasSudoBeenEstablished] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const baseRoute =
    basename === "/" ? "" : `${basename.match(/^\//) ? "" : "/"}${basename}`;
  const requestOtpRoute = `${baseRoute}${SudoRoutes.RequestOTP}`;
  const submitOtpRoute = `${baseRoute}${SudoRoutes.SubmitOTP}`;

  const checkSudo = useCallback(async () => {
    try {
      await fetchIsSudo();
      if (!hasSudoBeenEstablished) {
        setHasSudoBeenEstablished(true);
      }
    } catch (error) {
      if (hasSudoBeenEstablished) {
        setHasSudoBeenEstablished(false);
      }
    }
  }, []);

  const onSetInitialSteps = useCallback(async (steps: number) => {
    await checkSudo();
    setInitialSteps(steps);
  }, []);

  const useSetInitialSteps = useCallback(async (steps: number) => {
    useEffect(() => {
      onSetInitialSteps(steps);
    }, []);
  }, []);

  useEffect(() => {
    const hasDefaultChanged =
      originalRoute !== defaultSudoContext.originalRoute ||
      destinationRoute !== defaultSudoContext.destinationRoute ||
      formData !== defaultSudoContext.formData;

    if (
      ![
        requestOtpRoute,
        submitOtpRoute,
        originalRoute,
        destinationRoute,
      ].includes(location.pathname) &&
      hasDefaultChanged
    ) {
      setFormData(defaultSudoContext.formData);
      setDestinationRoute(defaultSudoContext.destinationRoute);
      setOriginalRoute(defaultSudoContext.originalRoute);
      setOnEstablishedSudo(defaultSudoContext.onEstablishedSudo);
    }
  }, [location.pathname]);

  const establishSudo = useCallback(
    async (destRoute: string, onEstablishedSudoCb?: OnEstablishedSudoType) => {
      if (!originalRoute) {
        setOriginalRoute(location.pathname);
      }
      setDestinationRoute(destRoute);
      setOnEstablishedSudo(() => onEstablishedSudoCb || null);
      try {
        await fetchIsSudo();
        await (onEstablishedSudoCb
          ? onEstablishedSudoCb?.()
          : Promise.resolve());
        navigate(destRoute);
      } catch (error) {
        navigate(requestOtpRoute);
      }
    },
    [location, navigate],
  );

  const cancelSudo = useCallback(() => {
    navigate(originalRoute || baseRoute);
  }, [navigate, originalRoute, setFormData]);

  const onFormDataChange = useCallback((update: any) => {
    setFormData((prev: any) => ({ ...prev, ...update }));
  }, []);

  const totalSteps = initialSteps
    ? initialSteps + (hasSudoBeenEstablished ? 0 : NUMBER_OF_SUDO_STEPS)
    : null;

  return (
    <SudoContext.Provider
      value={{
        originalRoute,
        destinationRoute,
        setDestinationRoute,
        setOriginalRoute,
        formData,
        setFormData,
        onFormDataChange,
        establishSudo,
        onEstablishedSudo,
        cancelSudo,
        useSetInitialSteps,
        totalSteps,
        requestOtpRoute,
        submitOtpRoute,
      }}
    >
      {children}
    </SudoContext.Provider>
  );
};
